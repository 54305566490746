/* Formulario.css */

.form-container {
  display: flex;
  flex-direction: column;
  max-width: 800px; /* Aumenta el valor de max-width según tus necesidades */
  margin: 0 auto;
  padding: 20px;
}

/* Estilo para los elementos con la clase "form-group" */
.form-group {
  margin-bottom: 20px;
}

/* Estilo para los elementos con la clase "locale" dentro de "form-group" */
.form-group .locale {
  margin-top: 0;
}

/* Estilo de las etiquetas */
label {
  font-weight: bold;
  margin-top: 10px;
}

/* Estilo de los campos de entrada */
.input-distribuidor {
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Estilo del botón de envío */
button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}