.productos-holder {
    margin-top: 120px;
  }
  
  .contenedor-roscado {
    width: auto;
    height: 320px;
    overflow: hidden;
    background-color: rgba(190, 177, 251, 0.666);
    box-sizing: border-box;
    border-radius: 6px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .contenedor-fresado {
    width: auto;
    height: 320px;
    overflow: hidden;
    background-color: rgba(190, 177, 251, 0.666);
    box-sizing: border-box;
    border-radius: 6px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .contenedor-perforado {
    width: auto;
    height: 320px;
    overflow: hidden;
    background-color: rgba(190, 177, 251, 0.666);
    box-sizing: border-box;
    border-radius: 6px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .contenedor-torneado {
    width: auto;
    height: 320px;
    overflow: hidden;
    background-color: rgba(190, 177, 251, 0.666);
    box-sizing: border-box;
    border-radius: 6px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }