.catalogo1 img {
    height: 180px;
    width: auto;
    border-radius: 2px;
}

.catalogo1 {
    display: flex;
    flex-direction: column;
    margin-top: 3em;
    justify-content: space-between;
    text-align: center;
}