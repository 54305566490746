.contenedor-escritorio {
    margin-top: 7%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.contenedor-escritorio img {
    width: auto;
    height: 45px;
    background-color: white;
}

@media (min-width: 1080px) {
    .contenedor-mobil {
      display: none;
    }
  
  }
  
  @media (max-width: 1080px){
    .contenedor-escritorio {
      display: none;
    }
  }