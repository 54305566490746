.distribuidor-holder {
  margin-top: 180px;
}

.contenedor-caja {
  display: flex;
  width: 1080px;
  height: 100%;
}

.tarjeta-distribuidor {
  margin: 10px;
  padding: 0px;
  background: linear-gradient(-35deg, #ccffff, #ccd4ff);
  width: 70%;
  height: 280px;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.estrellas {
  padding: 10px;
}

.texto-distribuidor {
  margin: 10px;
  padding: 10px;
}
